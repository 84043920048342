import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  HtmlEditor,
  Inject,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineInteraction, AiOutlineSave } from "react-icons/ai";
import { BiDetail } from "react-icons/bi";
import { BsFiletypePdf, BsTags } from "react-icons/bs";
import { LiaUndoSolid } from "react-icons/lia";
import { LuStamp } from "react-icons/lu";
import {
  MdChecklist,
  MdOutlineFeaturedPlayList,
  MdOutlineFileCopy,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { invalidateQueries } from "../../common/dataUtils";
import {
  formatDateTime,
  toDatabaseDate,
  toJSDate,
} from "../../common/dateUtils";
import {
  adaptiveCurrencyFormat,
  convertHtmlToText,
  currencyFormat,
  currencyValue,
  formatErrorMessage,
} from "../../common/utils";
import { JobContext } from "../../context/JobContext";
import { IPanelName, PanelsContext } from "../../context/PanelsContext";
import {
  duplicateJob,
  fetchFormFields,
  fetchJob,
  updateJob,
} from "../../services/jobApiService";
import { addJobLineItems } from "../../services/jobLineItemService";
import { showToaster } from "../../services/toastService";
import ProgressSpinner from "../common/ProgressSpinner";
import { editorSettings } from "./config";
import JobLineItems from "./lineItem/JobLineItems";
import JobChecklistPane from "./sidePane/JobChecklistPane";
import JobCustomerInfo from "./sidePane/JobCustomerInfo";
import JobFeatures from "./sidePane/JobFeatures";
import JobFeaturesPane from "./sidePane/JobFeaturesPane";
import JobLabelsPane from "./sidePane/JobLabelsPane";
import JobPills from "./sidePane/JobPills";
import ProposalNotesPane from "./sidePane/ProposalNotesPane";
import ProposalTemplatePane from "./sidePane/ProposalTemplatePane";
import JobSketches from "./sketch/JobSketches";
import { IFormInput, IJob, IProposalTemplate } from "./types";
import ProposalProductsPane from "./sidePane/ProposalProductsPane";
import { addJobLineItem } from "./../../services/jobLineItemService";

const JobEdit: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [job, setJob] = useState<IJob>({} as IJob);
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState<any>({});
  const [state, setState] = useState<any>({});
  const [isLineDirty, setIsLineDirty] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [panels, setPanels] = useState<any>({});
  const jobLineItemsRef = useRef<any>(null);
  let rteRef = useRef<any>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [formResult, { data: jobData, isLoading, error, isError }] = useQueries(
    {
      queries: [
        {
          queryKey: ["job-form-fields", 1],
          queryFn: fetchFormFields,
          staleTime: Infinity,
        },
        {
          queryKey: ["job", { jobId }],
          queryFn: () => fetchJob(jobId),
          staleTime: Infinity,
          retry: 1,
        },
      ],
    }
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: updateJob,
  });

  const { mutateAsync: mutateJobLineItems, isPending: isPendingLineItems } =
    useMutation({
      mutationFn: addJobLineItems,
    });

  useEffect(() => {
    if (formResult.data) {
      setFormFields(formResult.data.data);
    }
    if (jobData) {
      const processed = processJobData(jobData.data);
      setJob(processed);
      reset(processed);
    }
    if (formResult.isError) {
      console.log("Error: fetching form fields: ", formResult.error);
    }
    setLoading(isLoading);
  }, [jobData, formResult.data]);

  useEffect(() => {
    if (isError) {
      console.log("Error: fetching job data: ", error);
      showToaster("Error: " + formatErrorMessage(error.message), "error");
    }
  }, [isError]);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm();

  async function handleJobUpdate(data: any) {
    if (isDirty) {
      const cleanData = cleanDataForUpdate(data);

      try {
        const resp = await mutateAsync({ jobId, data: cleanData });
        const processed = processJobData(resp.data);
        showToaster("Job updated successfully");
        invalidateQueries(queryClient, ["jobs", "job", "lead", "reports"]);
      } catch (err: any) {
        showToaster("Error: " + formatErrorMessage(err.message), "error");
      }
    } else {
      console.log("no changes");
    }
    await jobLineItemsRef.current?.saveLineItems();
  }

  function cleanDataForUpdate(data: IFormInput): IFormInput {
    const updatedData = { ...data };

    if (updatedData.date_sold) {
      updatedData.date_sold = toDatabaseDate(updatedData.date_sold);
    }
    if (updatedData.start_date) {
      updatedData.start_date = toDatabaseDate(updatedData.start_date);
    }
    if (updatedData.signed_at) {
      updatedData.signed_at = toDatabaseDate(updatedData.signed_at);
    }
    if (updatedData.finish_date) {
      updatedData.finish_date = toDatabaseDate(updatedData.finish_date);
    }

    updatedData.size = currencyValue(updatedData.size).toString();
    updatedData.proposal_amount = currencyValue(
      updatedData.proposal_amount
    ).toString();

    return updatedData;
  }

  const saveBtnClass = classNames("btn btn-primary", {});

  function openFeaturePane(event: any): void {
    event.preventDefault();
    setPanelDisplay("features", true);
  }

  function openProgressPane(event: any): void {
    event.preventDefault();
    setPanelDisplay("progress", true);
  }

  function openChecklistPane(event: any): void {
    event.preventDefault();
    setPanelDisplay("checklist", true);
  }

  function openTemplatePane(event: any): void {
    event?.preventDefault();
    setPanelDisplay("templates", true);
    setPanelDisplay("showNotes", false);
  }

  function openNoteTemplatePane(event: any): void {
    event?.preventDefault();
    setPanelDisplay("templates", true);
    setPanelDisplay("showNotes", true);
  }

  function processJobData(data: any) {
    const processedData = {
      ...data,
      start_date: toJSDate(data.start_date),
      finish_date: toJSDate(data.finish_date),
      date_sold: toJSDate(data.date_sold),
      signed_at: toJSDate(data.signed_at),
      size: adaptiveCurrencyFormat(data.size),
      proposal_amount: currencyFormat(data.proposal_amount),
    };
    return processedData;
  }

  async function openJobPDF(event: any) {
    event.preventDefault();
    await handleSubmit(handleJobUpdate)();
    navigate(`/jobs/${job.uid}/pdf`, { replace: false });
  }

  async function addJobLinesFromTemplate(lines: any[]) {
    if (panels.showNotes) {
      return await addJobProposalNotesFromTemplate(lines);
    }
    return await addJobLineItemsFromTemplate(lines);
  }

  function findMaxRank() {
    const maxRank = job.line_items.reduce(
      (max: number, item: any) => (max = Math.max(max, item.sort_index)),
      0
    );
    return maxRank;
  }

  async function addJobLineItemsFromNotes(lines: any[]) {
    console.log("addJobLineItemsFromNotes", lines);
    let maxRank = findMaxRank();
    const payload = lines?.map((line) => {
      maxRank++;
      return {
        ...line,
        job_id: job.id,
        sort_index: maxRank,
      };
    });

    if (isEmpty(payload)) {
      return;
    }

    try {
      await mutateJobLineItems(payload);
      invalidateQueries(queryClient, ["job"]);
    } catch (err: any) {
      console.log("addJobLineItemsFromNotes error:", err);
      showToaster("Error: " + formatErrorMessage(err.message), "error");
    }
  }

  async function addJobLineItemsFromTemplate(lines: IProposalTemplate[]) {
    let maxRank = findMaxRank();
    const payload = lines?.map((line) => {
      maxRank++;
      const total = Number(line.rate) || 0;
      return {
        description: convertHtmlToText(line.text),
        template_id: line.id,
        job_id: job.id,
        sort_index: maxRank,
        unit: line.rate_unit,
        rate: line.rate,
        total,
      };
    });

    if (isEmpty(payload)) {
      return;
    }

    try {
      await mutateJobLineItems(payload);
      invalidateQueries(queryClient, ["job"]);
    } catch (err: any) {
      console.log("addJobLineItemsFromTemplate error:", err);
      showToaster("Error: " + formatErrorMessage(err.message), "error");
    }
  }

  async function addJobLineItemFromProduct(products: any[]) {
    let maxRank = findMaxRank();
    const payload = products.map((product) => {
      return {
        description: product.name,
        job_id: job.id,
        sort_index: ++maxRank,
        unit: product.unit,
        rate: product.rate,
        total: product.size * product.rate,
        quantity: product.size,
      };
    });

    if (isEmpty(payload)) {
      showToaster("No products selected", "error");
      return;
    }

    try {
      await addJobLineItems(payload);
      invalidateQueries(queryClient, ["job"]);
    } catch (err: any) {
      console.log("addJobLineItemFromProduct error:", err);
      showToaster("Error: " + formatErrorMessage(err.message), "error");
    }
  }

  const handleTotalUpdated = (newAmount: string, newSize: string) => {
    if (!job.proposal_note && job.proposal) return;
    const amount = getValues("proposal_amount");
    if (currencyValue(amount) !== currencyValue(newAmount)) {
      console.log("handleTotalUpdated set new proposal_amount", newAmount);
      setValue("proposal_amount", newAmount, { shouldDirty: true });
    }

    const size = getValues("size");
    if (currencyValue(size) !== currencyValue(newSize)) {
      console.log("handleTotalUpdated set new size", newSize);
      setValue("size", newSize, { shouldDirty: true });
    }
    console.log(
      `handleTotalUpdated new_amount: ${newAmount} old_amount: ${amount},  new_size:${newSize} old_size: ${size}`
    );
  };

  async function addJobProposalNotesFromTemplate(lines: IProposalTemplate[]) {
    console.log("addJobProposalNotesFromTemplate", lines);
    if (lines.length === 0) return;
    let proposal_note = rteRef.current?.value || "";
    proposal_note += "<p/>" + lines?.map((d) => d.text).join("<p />");
    rteRef.current.value = proposal_note;
    setValue("proposal_note", proposal_note, { shouldDirty: true });
    await handleSubmit(handleJobUpdate)();
  }

  const goToView = (e: any) => {
    e.preventDefault();
    navigate("/jobs/" + jobId);
  };

  const handleUndo = (e: any) => {
    e.preventDefault();
    reset();
    rteRef.current.value = job.proposal_note;
    jobLineItemsRef.current?.reset();
  };

  const dateSoldReg = register("date_sold");
  const startDateReg = register("start_date");
  const signedAtReg = register("signed_at");
  const finishDateReg = register("finish_date");

  const fixedTopBarClass = classNames("fixed-top-bar", {
    "d-none": !hasChanged,
  });

  function handleSetLoading(loading: boolean): void {
    setLoading(loading);
  }

  async function openInstallerPDF(event: any) {
    event.preventDefault();
    await handleSubmit(handleJobUpdate)();
    navigate(`/jobs/${job.uid}/installer`, { replace: false });
  }

  function handleEditorChange(event: any): void {
    const value = event.value || "";
    console.log("handleEditorChange", value);
    setValue("proposal_note", value, { shouldDirty: true });
  }

  function handleOnDelete(id: any) {
    const newLabels = job.labels.filter((label: any) => label.pivot.id !== id);
    setJob({ ...job, labels: newLabels });
  }

  async function handleDuplicateJob(event: any) {
    event.preventDefault();
    const result = await Swal.fire({
      text: "Are you sure you want to create a copy of this job?",
      position: "top",
      confirmButtonText: "Yes",
      confirmButtonColor: "#0d6efd",
      showCancelButton: true,
    });
    if (!result.isConfirmed) return;

    setLoading(true);
    try {
      const resp = await duplicateJob(jobId);
      if (resp.data?.id) {
        navigate(`/jobs/${resp.data.id}/edit`, { replace: false });
      }
    } catch (err: any) {
      console.log("duplicateJob error:", err);
      showToaster("Error: " + formatErrorMessage(err.message), "error");
    }
  }

  useEffect(() => {
    const value = getValues();
    console.log("JobEdit form values:", value);
    console.log("JobEdit isDirty:", isDirty);
    console.log("JobEdit dirtyFields:", dirtyFields);
    const _isDirty = !isEmpty(dirtyFields) || isLineDirty;
    setHasChanged(_isDirty);
  }, [getValues()]);

  function setPanelDisplay(panel: IPanelName, isVisible: boolean) {
    setPanels((prev: any) => ({ ...prev, [panel]: isVisible }));
  }

  if (isError) {
    return (
      <div className="row m-3">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>Job not found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container p-5 pt-3">
      <div>
        <ProgressSpinner show={loading || isPending || isPendingLineItems} />
        <ToastContainer />
        <PanelsContext.Provider value={{ panels, setPanelDisplay }}>
          <JobFeaturesPane
            jobId={jobId}
            features={formFields.features}
            jobFeatures={job.features}
            updateJobFeatures={(features: any) => setJob({ ...job, features })}
            setLoading={(isLoading: boolean) => setLoading(isLoading)}
          />
          <JobLabelsPane
            jobId={jobId}
            labels={formFields.labels}
            jobLabels={job.labels}
            updateJobLabels={(labels: any) => setJob({ ...job, labels })}
            setLoading={(isLoading: boolean) => setLoading(isLoading)}
          />
          <JobChecklistPane
            jobId={jobId}
            checklist={formFields.checklist}
            jobChecklist={job.checklist}
            updateJobChecklist={(checklist: any) =>
              setJob({ ...job, checklist })
            }
            setLoading={(isLoading: boolean) => setLoading(isLoading)}
          />
          <ProposalTemplatePane
            addJobLineItems={addJobLinesFromTemplate}
            jobId={job.id}
          />
          <ProposalNotesPane
            addJobLineItems={addJobLineItemsFromNotes}
            proposalNotes={job?.proposal_note || job?.proposal?.text}
          />
          <ProposalProductsPane addJobLineItems={addJobLineItemFromProduct} />
        </PanelsContext.Provider>
        <div className={fixedTopBarClass}>
          <button
            className={saveBtnClass}
            onClick={handleSubmit(handleJobUpdate)}
            disabled={job.read_only}
          >
            <AiOutlineSave className="mr-2 mb-1" />
            Save
          </button>
          <button className="btn btn-light ml-5" onClick={handleUndo}>
            <LiaUndoSolid className="mr-2 mb-1" />
            Discard
          </button>
        </div>

        <div className="row mb-4">
          <div className="col-md-3">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <AiOutlineInteraction className="mr-2 mb-1" />
                Actions
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={openFeaturePane}
                  >
                    <MdOutlineFeaturedPlayList className="mr-2 mb-1" />
                    Features
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={openProgressPane}
                  >
                    <BsTags className="mr-2 mb-1" />
                    Progress
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={openChecklistPane}
                  >
                    <MdChecklist className="mr-2 mb-1" />
                    Checklist
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={openTemplatePane}
                  >
                    <LuStamp className="mr-2 mb-1" />
                    Line Templates
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={handleDuplicateJob}
                  >
                    <MdOutlineFileCopy className="mr-2 mb-1" />
                    Duplicate Job
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" onClick={goToView}>
                    <BiDetail className="mr-2 mb-1" />
                    Detail View
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" onClick={openJobPDF}>
                    <BsFiletypePdf className="mr-2 mb-1" />
                    View PDF
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={openInstallerPDF}
                  >
                    <BsFiletypePdf className="mr-2 mb-1" />
                    Installer Sheet
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              <JobPills
                jobId={jobId}
                labels={job.labels}
                onDeleted={handleOnDelete}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <JobCustomerInfo lead={job.lead} />
          </div>
          <div className="col">
            <div className="form-group row">
              <label htmlFor="name" className="col-sm-2 form-label">
                Title:
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  {...register("name")}
                />
              </div>
            </div>
            <div className="form-group row mt-2">
              <label htmlFor="code" className="col-sm-2 form-label">
                Number:
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  {...register("code")}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="job_type" className="form-label">
              Job Type
            </label>
            <select className="form-select" {...register("job_type")}>
              {formFields.jobTypes?.map((item: any) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="contact" className="form-label">
              Customer Type
            </label>
            <select className="form-select" {...register("customer_type")}>
              {formFields.customerTypes?.map((item: any) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="contractor" className="form-label">
                Contractor
              </label>
              <input
                type="text"
                className="form-control"
                {...register("contractor")}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="property_type" className="form-label">
                Property Type
              </label>
              <select className="form-select" {...register("property_type")}>
                {formFields.propertyTypes?.map((item: any) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="date_sold" className="form-label">
                Date Sold
              </label>
              <DatePickerComponent
                name={dateSoldReg.name}
                onChange={dateSoldReg.onChange}
                onBlur={dateSoldReg.onBlur}
                ref={dateSoldReg.ref}
              />
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-3">
            <label htmlFor="size" className="form-label">
              Size
            </label>
            <input
              type="text"
              className="form-control"
              {...register("size")}
              placeholder="0.00"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="sqft_price" className="form-label">
              SQ.FT. Price
            </label>
            <input
              type="text"
              className="form-control"
              {...register("sqft_price")}
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="proposal_amount" className="form-label">
                Proposal Amount
              </label>
              <input
                type="text"
                className="form-control"
                {...register("proposal_amount")}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="invoiced_amount" className="form-label">
                Amount Invoiced
              </label>
              <input
                type="text"
                className="form-control"
                {...register("invoiced_amount")}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="start_date" className="form-label">
                Start Date
              </label>
              <DatePickerComponent
                name={startDateReg.name}
                onChange={startDateReg.onChange}
                onBlur={startDateReg.onBlur}
                ref={startDateReg.ref}
              />
            </div>
          </div>
        </div>

        <div className="mt-3">
          Features:
          <a
            href="#"
            className="ml-2 text-decoration-none"
            onClick={openFeaturePane}
          >
            Add Features
          </a>
          <JobFeatures job={job} />
        </div>

        <JobContext.Provider value={[setIsLineDirty]}>
          <PanelsContext.Provider value={{ panels, setPanelDisplay }}>
            <JobLineItems
              job={job}
              setLoading={setLoading}
              onTotalUpdated={handleTotalUpdated}
              ref={jobLineItemsRef}
            />
          </PanelsContext.Provider>
        </JobContext.Provider>

        <div className="row">
          <div className="col-md-9 mt-5 mb-2">
            <label htmlFor="notes" className="form-label">
              Proposal Notes
            </label>
            <a
              href="#"
              className="ml-2 text-decoration-none"
              onClick={openNoteTemplatePane}
            >
              Add Templates
            </a>
          </div>
        </div>

        <div className="row mb-4 d-flex justify-content-center">
          <div className="" style={{ width: 900 }}>
            <RichTextEditorComponent
              toolbarSettings={editorSettings}
              value={job?.proposal_note || job?.proposal?.text}
              height={272}
              ref={(editor) => (rteRef.current = editor)}
              change={handleEditorChange}
            >
              <Inject services={[Toolbar, HtmlEditor]} />
            </RichTextEditorComponent>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6 mb-3">
            <label htmlFor="notes" className="form-label">
              Job Notes:
            </label>
            <textarea
              className="form-control"
              rows={5}
              defaultValue={job.note?.note}
              {...register(`note.note`)}
            ></textarea>
          </div>
          <div className="col-lg-6 mb-3">
            <label htmlFor="notes" className="form-label">
              Installer Notes:
            </label>
            <textarea
              className="form-control"
              rows={5}
              defaultValue={job.installer_note?.note}
              {...register(`installer_note.note`)}
            ></textarea>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-3">
            <label htmlFor="crew" className="form-label">
              Crew
            </label>
            <input
              type="text"
              className="form-control"
              {...register("crew")}
              placeholder="Brad"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="downpayment" className="form-label">
              Down payment
            </label>
            <input
              type="text"
              className="form-control"
              {...register("downpayment")}
            />
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="signed_at" className="form-label">
                Signature Date
              </label>
              <DatePickerComponent
                name={signedAtReg.name}
                onChange={signedAtReg.onChange}
                onBlur={signedAtReg.onBlur}
                ref={signedAtReg.ref}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="finish_date" className="form-label">
                Finish Date
              </label>
              <DatePickerComponent
                name={finishDateReg.name}
                onChange={finishDateReg.onChange}
                onBlur={finishDateReg.onBlur}
                ref={finishDateReg.ref}
              />
            </div>
          </div>
        </div>
        <JobSketches job={job} setLoading={handleSetLoading} />
        <div className="d-flex justify-content-end text-muted text-sm mt-5">
          <small>Updated on: {formatDateTime(job.updated_at)}</small>
        </div>
      </div>
    </div>
  );
};

export default JobEdit;
