import { apiUrl } from "../config/app";
import ApiService from "./ApiService";

export const fetchProducts = async () => {
  const apiService = new ApiService();
  const resp = await apiService.get(apiUrl + "/products");
  console.log("products fetched ");
  return resp;
};

export const upsertProduct = async (product: any) => {
  const apiService = new ApiService();
  const resp = await apiService.post(apiUrl + "/products", product);
  console.log("product saved ");
  return resp;
};

export const deleteProduct = async (id: number) => {
  const apiService = new ApiService();
  const resp = await apiService.delete(apiUrl + "/products/" + id);
  console.log("product deleted ");
  return resp;
};
