import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { ToastContainer } from "react-toastify";
import { fetchProducts } from "../../../services/productApiService";
import BreadcrumbsComponent from "../../common/BreadcrumbsComponent";
import ProductEditDialog from "./ProductEditDialog";
import { IProduct } from "./types";
import { toLower } from "lodash";
import {
  currencyFormat,
  formatErrorMessage,
  toTitleCase,
} from "../../../common/utils";
import { useQuery } from "@tanstack/react-query";

const ProductList: React.FC<any> = (props) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [search, setSearch] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState(1);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const { data, isError, error } = useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    staleTime: Infinity,
  });

  useEffect(() => {
    const dt = data?.data || [];
    setProducts(dt);
    setFilteredProducts(dt);
  }, [data]);

  useEffect(() => {
    handleSearch();
  }, [search, products, selectedBrand, selectedCategory]);

  useEffect(() => {
    updateCategories();
    updateBrands();
  }, [filteredProducts]);

  function handleSearch(): void {
    const searchValue = search?.toLowerCase() ?? "";
    let filtered = [...products];

    if (search) {
      filtered = filtered.filter((product) =>
        product.name?.toLowerCase().includes(searchValue)
      );
    }

    if (selectedCategory?.length > 0) {
      filtered = filtered.filter(
        (product) => product.category === selectedCategory
      );
    }

    if (selectedBrand?.length > 0) {
      filtered = filtered.filter((product) => product.brand === selectedBrand);
    }

    setFilteredProducts(filtered);
  }

  function handleCategoryFilter(e: any): void {
    setSelectedCategory(e.target.value);
  }

  function handleBrandFilter(e: any): void {
    setSelectedBrand(e.target.value);
  }

  function handleSort(e: any): void {
    e.preventDefault();
    const sortKey = e.target.id;
    const sorted = [...filteredProducts];
    const sortDirectionOpposite = sortDirection * -1;

    sorted.sort((a, b) => {
      const val1 =
        sortKey === "price" ? parseFloat(a[sortKey]) : toLower(a[sortKey]);
      const val2 =
        sortKey === "price" ? parseFloat(b[sortKey]) : toLower(b[sortKey]);
      return val1 > val2 ? sortDirection : sortDirectionOpposite;
    });

    setSortDirection(sortDirectionOpposite);
    setFilteredProducts(sorted);
  }

  function updateCategories(): void {
    const categories = filteredProducts
      .map((product) => product.category)
      .filter((category) => category);
    const uniqueCategories = Array.from(new Set(categories));
    setCategories(uniqueCategories);
  }

  function updateBrands(): void {
    const brands = filteredProducts
      .map((product) => product.brand)
      .filter((brand) => brand);
    const uniqueBrands = Array.from(new Set(brands));
    setBrands(uniqueBrands);
  }

  const handleEditProduct = (e: any, product: any) => {
    e.preventDefault();
    setSelectedProduct(product);
    setShowEditModal(true);
  };

  const handleCreateProduct = (e: any) => {
    e.preventDefault();
    setSelectedProduct({});
    setShowEditModal(true);
  };

  const handleOnHideModal = () => {
    setSelectedProduct({});
    setShowEditModal(false);
  };

  function handleInputChange(event: any): void {
    const value = event.target.value;
    setSearch(value);
  }

  function handleOnSaved(product: IProduct): void {
    const updatedList = products.map((item: any) => {
      if (item.id === product.id) {
        return product;
      }
      return item;
    });
    setProducts(updatedList);
    setSelectedProduct({});
  }

  if (isError) {
    return (
      <div className="m-1">
        <div className="alert alert-danger">
          <h4 className="alert-heading">Error!</h4>
          <p>{formatErrorMessage(error.message)}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <ProductEditDialog
          show={showEditModal}
          onHide={handleOnHideModal}
          onSaved={handleOnSaved}
          product={selectedProduct}
        />
        <ToastContainer />
        <BreadcrumbsComponent
          links={[{ to: "/settings", label: "Settings" }]}
          leaf="Products"
        />
        <div>
          <div className="page-title">Products</div>
        </div>
        <div className="row px-3">
          <div className="d-flex justify-content-end mr-5">
            <div className="col-sm-3 mx-2">
              <select className="form-select" onChange={handleCategoryFilter}>
                <option value="">Category</option>
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {toTitleCase(category)}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-3 mx-2">
              <select className="form-select" onChange={handleBrandFilter}>
                <option value="">Brand</option>
                {brands.map((brand) => (
                  <option key={brand} value={brand}>
                    {toTitleCase(brand)}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="Find Product"
              className="form-control w-25 mr-2"
              onChange={handleInputChange}
            />
            <button
              className="btn btn-primary mr-5"
              onClick={handleCreateProduct}
            >
              Add Item
            </button>
          </div>
        </div>
        <div className="row p-5 pt-3">
          <table className="table table-striped">
            <thead className="table-dark">
              <tr>
                <th>
                  <a
                    href="#"
                    id="name"
                    onClick={handleSort}
                    className="text-decoration-none text-white"
                  >
                    Name
                  </a>
                </th>
                <th>
                  <a
                    href="#"
                    id="price"
                    onClick={handleSort}
                    className="text-decoration-none text-white"
                  >
                    Price
                  </a>
                </th>
                <th>
                  <a
                    href="#"
                    id="sq_ft_min"
                    onClick={handleSort}
                    className="text-decoration-none text-white"
                  >
                    SQ FT
                  </a>
                </th>
                <th>
                  <a
                    href="#"
                    id="brand"
                    onClick={handleSort}
                    className="text-decoration-none text-white"
                  >
                    Brand
                  </a>
                </th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts?.map((product: IProduct) => (
                <tr key={product.id}>
                  <th scope="row">{product.name}</th>
                  <td>{currencyFormat(product.price, "$")}</td>
                  <td>{product.sq_ft_min}</td>
                  <td>{product.brand}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => handleEditProduct(e, product)}
                      className="btn btn-default"
                    >
                      <BsPencilSquare />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ProductList;
