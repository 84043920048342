import { generate } from "@wcj/generate-password";
import currency from "currency.js";
import { convert } from "html-to-text";
import { startCase, toLower, trim } from "lodash";
import { DateTime } from "luxon";
import { truncate as ld_truncate } from "lodash";

export const arraysAreSimilar = (a: any[], b: any[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const counts: any = {};

  for (let i = 0; i < a.length; i++) {
    counts[a[i]] = (counts[a[i]] || 0) + 1;
    counts[b[i]] = (counts[b[i]] || 0) - 1;
  }

  return Object.values(counts).every((count) => count === 0);
};

export const isValidEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

export const isValidPhoneNumber = (phone: string) => {
  const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return re.test(phone);
};

export function currencyFormat(total: any, symbol = ""): string {
  const options: any = { symbol: symbol };
  return currency(total, options).format();
}

export function currencyValue(total: any): number {
  return currency(total).value;
}

export const numberFormat = (val: any, precision = 2) => {
  return currency(val, { precision, symbol: "" }).format();
};

export function adaptiveCurrencyFormat(number: any) {
  if (!number) return "";
  number = currencyValue(number);
  let precision = 2;
  if (number % 1 === 0) {
    precision = 0;
  } else if (number % 1 < 0.1) {
    precision = 1;
  }
  const options: any = { symbol: "", precision: precision };
  return currency(number, options).format();
}

export function calculateTotal(numb1: any, numb2: any) {
  const val1 = currency(numb1).value;
  const val2 = currency(numb2).value;
  const total = val1 == 0 && val2 == 0 ? 0 : (val1 || 1) * (val2 || 1);
  return total;
}

export function toArray(value: any) {
  if (!value) return [];
  if (Array.isArray(value)) {
    return value;
  }
  return [value];
}

export function wait(seconds: number) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}

export function getVisibility(value: string) {
  if (value == "2") return "public";
  if (value == "1") return "protected";
  return "private";
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<T>) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, wait);
  };
}

export function convertHtmlToText(text: string): string {
  const newText = convert(text); // convert html to text
  return newText.replace(/^-/, "");
}

export function generatePassword(): string {
  const password = generate({
    length: 8,
    numeric: true,
    special: false,
  });

  return password;
}

export function getLocation(data: any): string {
  return trim(
    `${data.street}, ${data.city ?? ""}, ${data.state ?? ""} ${data.zip ?? ""}`,
    ", "
  );
}

export function getCleanUrl(url: string): string {
  const queryStringIndex = url.indexOf("?");
  const urlWithoutQuery =
    queryStringIndex !== -1 ? url.slice(0, queryStringIndex) : url;
  return urlWithoutQuery;
}

export function getEventTime(start: string, duration: number): string {
  const startTime = DateTime.fromJSDate(new Date(start));
  const endTime = startTime.plus({ minutes: duration });
  return endTime.toISO();
}

export function getQueryParams(key: string): any {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
}

export const NumberToMonth = (num: number) => {
  const months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[num];
};

export function getLastMonths() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const last12Months = [];

  for (let i = 0; i < 12; i++) {
    const month = ((currentMonth - i + 12) % 12) + 1;
    const year = currentMonth - i >= 0 ? currentYear : currentYear - 1;
    last12Months.unshift({ month, year });
  }

  console.log(last12Months);
  return last12Months;
}

export function getCounts(last12Months: any[], items: any[]): number[] {
  return last12Months.map((item: any) => {
    const res = items.find((item2: any) => item2.month === item.month);
    return res?.count || 0;
  });
}

export function getMonthlyCount(items: any[]): number[] {
  return items.map((item: any) => item.count);
}

export function getCurrentMonthCount(items: any[]): number {
  const currMonth = new Date().getMonth() + 1;
  return items.find((item: any) => item.month == currMonth)?.count || 0;
}

export function getCurrentMonthAmount(items: any[]): number {
  const currMonth = new Date().getMonth() + 1;
  return items.find((item: any) => item.month == currMonth)?.amount || 0;
}

export function monthToNumber(label: string) {
  switch (toLower(label)) {
    case "jan":
      return 1;
    case "feb":
      return 2;
    case "mar":
      return 3;
    case "apr":
      return 4;
    case "may":
      return 5;
    case "jun":
      return 6;
    case "jul":
      return 7;
    case "aug":
      return 8;
    case "sep":
      return 9;
    case "oct":
      return 10;
    case "nov":
      return 11;
    case "dec":
      return 12;
    default:
      return 0;
  }
}

export function parseQueryStringToJSON(queryString?: string): any {
  const actualQueryString = queryString || window.location.search;
  const params = new URLSearchParams(actualQueryString);
  const json: any = {};

  params.forEach((value, key) => {
    json[key] = value;
  });

  return json;
}

export function getLocalUtcDifference() {
  const date = new Date();
  const timezoneOffsetInHours = date.getTimezoneOffset() / 60;
  return timezoneOffsetInHours;
}

export function addHours(appt: Date, hoursDiff: number): Date {
  const newAppt = new Date(appt);
  newAppt.setHours(newAppt.getHours() + hoursDiff);
  return newAppt;
}

export function toTitleCase(str: string) {
  return startCase(toLower(str));
}

export function toTitleCaseSingle(str: string) {
  if (str?.toLowerCase() == "status") return toTitleCase(str);
  return toTitleCase(str).slice(0, -1);
}

export function formatErrorMessage(err: any): string {
  if (!err) return "";
  return JSON.stringify(err).replace(/\\"/g, "").replace(/"/g, "");
}

export function getFileSize(file: File) {
  const size = file.size;
  if (size < 1024) {
    return size + "bytes";
  } else if (size > 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + "KB";
  } else if (size > 1048576) {
    return (size / 1048576).toFixed(1) + "MB";
  }
}

export function isFileTooLarge(file: File, maxMb: number = 1) {
  const maxSizeBytes = maxMb * 1024 * 1024;
  return file?.size > maxSizeBytes;
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataUrl = reader.result as string;
      const base64 = dataUrl.split(",")[1];
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function updateDisplayOrder(sorted: any[]) {
  return sorted.map((item, index) => ({
    ...item,
    display_order: index + 1,
  }));
}

export function truncate(str: string, n = 20) {
  return ld_truncate(str, { length: n });
}

export function isIOS() {
  const isIpad =
    /Mac/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;
  return isIpad;
}

export function isTokenExpired(): boolean {
  const expires_at = localStorage.getItem("expires_at");
  if (!expires_at) {
    return true;
  }
  const expiry = parseInt(expires_at);
  return Date.now() > expiry;
}
