import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { upsertProduct } from "../../../services/productApiService";
import { IProduct } from "./types";
import ProgressSpinner from "../../common/ProgressSpinner";

interface IProps {
  show: boolean;
  product?: IProduct;
  onHide: (e?: any) => void;
  onSaved: (product: IProduct) => void;
}

const ProductEditDialog: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { mutateAsync } = useMutation({
    mutationFn: upsertProduct,
  });

  const {
    register,
    reset,
    formState: { isDirty },
    getValues,
  } = useForm();

  useEffect(() => {
    reset(props.product);
    setShowModal(props.show);
  }, [props.show, props.product]);

  const handleCloseDialog = () => {
    setShowModal(false);
    props.onHide();
  };

  const handleSaveProduct = async () => {
    const data: any = getValues();
    setLoading(true);

    try {
      const resp: any = await mutateAsync(data);
      console.log("Saved product ", resp.data);
      props.onSaved(resp.data);
      toast.success("item updated: " + resp.data.name);
    } catch (error) {
      console.error("Error saving product ", error);
      toast.error("Error saving product: " + JSON.stringify(error));
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      keyboard={false}
      onHide={handleCloseDialog}
      dialogClassName="modal-wide"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <ProgressSpinner show={loading} />
          {props.product?.id ? "Update" : "Create"} Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row p-3 g-3 align-items-center">
          <div className="mb-3 row">
            <label
              htmlFor="name"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Product Name"
                {...register("name")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="price"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Price
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="1.99"
                {...register("price")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="sq_ft_min"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              SQ FT Min
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="300, 700, 1000, 2000"
                {...register("sq_ft_min")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="brand"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Brand
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Belgard, Pavestone, Daltile"
                {...register("brand")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="category"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Category
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="Paver, Wall, Cap, Removal"
                {...register("category")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="unit"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Unit
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="SQ FT, LF, EACH, Pallet"
                {...register("unit")}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="notes"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Notes
            </label>
            <div className="col-sm-8">
              <textarea
                className="form-control"
                {...register("notes")}
              ></textarea>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="discount"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Discount
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="15%"
                {...register("discount")}
              ></input>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="cost"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Cost
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                placeholder="1.99"
                {...register("cost")}
              ></input>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="image"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Image
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                {...register("image")}
              ></input>
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="is_active"
              className="col-sm-3 col-form-label text-end fw-semibold"
            >
              Status
            </label>
            <div className="col-sm-4">
              <select className="form-select" {...register("is_active")}>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleSaveProduct}
          disabled={!isDirty}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductEditDialog;
