import React from "react";
import { BiTrash } from "react-icons/bi";
import { MdFlashOn } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from "../../../common/dateUtils";
import { constants } from "../../../config/constants";
import { deleteJob } from "../../../services/jobApiService";
import { showToaster } from "../../../services/toastService";
import ConfirmDialog from "../../common/ConfirmDialog";
import { IJob } from "../types";

interface IProps {
  jobs: any[];
  onDeleteJob: (id: string) => void;
}

const LeadJobsDisplay: React.FC<IProps> = (props) => {
  const { jobs, onDeleteJob } = props;
  const [state, setState] = React.useState({} as any);
  const navigate = useNavigate();

  function handleEditJob(id: string) {
    navigate(`/jobs/${id}/edit`);
  }

  async function handleDeleteJob() {
    handleCancelDelete();
    const resp = await deleteJob(state.id);
    if (resp.status == constants.SUCCESS_STATUS) {
      console.log("Deleted job " + state.id);
      onDeleteJob(state.id);
    } else {
      showToaster("Error deleting job " + JSON.stringify(resp.error), "error");
    }
  }

  function handleCancelDelete(): void {
    setState({ ...state, showDeleteModal: false });
  }

  function handleDeleteClick(id: string): void {
    setState({ ...state, showDeleteModal: true, id });
  }

  function showInstallerPayments(jobId: any): void {
    event.preventDefault();
    navigate(`/jobs/${jobId}/installer-payments`);
  }

  return (
    <>
      <ConfirmDialog
        show={state.showDeleteModal}
        message={`Are you sure you want to delete job: '${state.id}'?`}
        onHide={handleCancelDelete}
        handleDeleteClick={handleDeleteJob}
      />

      <div className="mt-1">
        <ul className="list-group">
          {jobs?.map((job: IJob) => (
            <li
              key={job.id}
              className={
                "list-group-item border mb-3 " +
                (job.date_sold ? "border-warning" : "")
              }
            >
              <div className="row">
                <Link
                  className="text-decoration-none col-9"
                  to={`/jobs/${job.id}`}
                >
                  <div className="col-10">
                    {job.code ?? job.id}
                    <span className="text-secondary ml-5">{job.name}</span>
                    <span className="text-secondary ml-5 fw-lighter fst-italic text-sm">
                      {formatDateTime(job.created_at)}
                    </span>
                    {job.date_sold && (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                        Sold
                      </span>
                    )}
                  </div>
                </Link>
                <div
                  className="col d-flex justify-content-end"
                  style={{ maxHeight: "32px" }}
                >
                  <div className="dropdown dropdown-center">
                    <a
                      className="btn btn-primary btn-sm dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MdFlashOn />
                    </a>

                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleEditJob(job.id)}
                        >
                          <TbEdit className="mr-1" />
                          Edit
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => handleDeleteClick(job.id)}
                        >
                          <BiTrash className="mr-1" /> Delete
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={() => showInstallerPayments(job.id)}
                        >
                          Installer Payment
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LeadJobsDisplay;
