import React, { ChangeEvent, useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { usePanelsContext } from "../../../context/PanelsContext";
import { IProposalTemplate } from "../types";
import { useQuery } from "@tanstack/react-query";
import { fetchProducts } from "../../../services/productApiService";
import { currencyFormat, toTitleCase } from "./../../../common/utils";
import { isEmpty, toLower, toUpper } from "lodash";
import { IProduct, IProductSize } from "../../settings/product/types";

interface IProps {
  addJobLineItems: (lines: any[]) => void;
}

const ProposalProductsPane: React.FC<IProps> = (props) => {
  const { panels, setPanelDisplay } = usePanelsContext();
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>(null);
  const [products, setProducts] = useState<any[]>(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedProductName, setSelectedProductName] = useState<any>(null);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>(null);
  const [size, setSize] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const isPaneOpen = !!panels.products;

  const { data, isError, error } = useQuery({
    queryKey: ["proposal-products"],
    queryFn: fetchProducts,
    staleTime: Infinity,
    enabled: isPaneOpen,
    retry: 1,
  });

  useEffect(() => {
    if (data) {
      const prods = data.data || [];
      processCategories(prods);
      setSize(null);
    }
  }, [data]);

  useEffect(() => {
    filterBrands();
    filterProducts();
    setTotal(0);
  }, [selectedCategory]);

  useEffect(() => {
    filterProducts();
  }, [selectedBrand]);

  useEffect(() => {
    if (!selectedProductName) {
      setTotal(0);
      setSelectedProduct(null);
      return;
    }
    const prod = findProductByName(selectedProductName);
    setSelectedProduct(prod);
  }, [selectedProductName]);

  function processCategories(data: any) {
    const categories = data.map((item: any) => item.category);
    setCategories(Array.from(new Set(categories)));
  }

  function filterBrands() {
    setTotal(0);
    setBrands(null);
    setSelectedBrand(null);
    setSelectedProductName(null);

    if (!selectedCategory) {
      setSize(null);
      return;
    }

    const selected = toLower(selectedCategory);
    const filteredData =
      data?.data?.filter(
        (item: any) => item.brand && toLower(item.category) === selected
      ) || [];

    const uniqueBrands = Array.from(
      new Set(filteredData.map((item: any) => item.brand))
    );

    setBrands(uniqueBrands);
  }

  function filterProducts() {
    setTotal(0);
    setSelectedProductName(null);

    if (!selectedCategory) {
      setSelectedProductName(null);
      setSize(null);
      setTotal(0);
      setBrands(null);
      return;
    }

    const selectedCategoryLower = toLower(selectedCategory);
    let filtered =
      data?.data?.filter(
        (item: any) => toLower(item.category) === selectedCategoryLower
      ) || [];

    if (selectedBrand) {
      const selectedBrandLower = toLower(selectedBrand);
      filtered = filtered.filter(
        (item: any) => toLower(item.brand) === selectedBrandLower
      );
    }

    const uniqueProductNames = Array.from(
      new Set(filtered.map((item: any) => item.name))
    );

    setProducts(uniqueProductNames);
  }

  function handleAddLineClick() {
    if (!selectedProductName) {
      return;
    }
    const prod = findProductByName(selectedProductName);
    const lines = [
      {
        name: prod.name,
        unit: prod.unit,
        rate: findPriceBySize(prod, parseFloat(size)),
        size: parseFloat(size),
      },
    ];
    props.addJobLineItems(lines);
    console.log("Adding lines", selectedProductName);
    handleClosePane();
  }

  function findPriceBySize(product: IProductSize, enteredSize: number): number {
    if (!product.sizes || product.sizes.length === 0) {
      return 0;
    }

    // Sort sizes in descending order
    const sortedSizes = product.sizes.slice().sort((a, b) => b.size - a.size);

    // Find the appropriate price based on the entered size
    for (const { size, price } of sortedSizes) {
      if (enteredSize >= size) {
        return price;
      }
    }

    // If entered size is smaller than the smallest size, use the smallest size's price
    const smallestSize = sortedSizes[sortedSizes.length - 1];
    return smallestSize.price;
  }

  function handleClosePane(): void {
    setPanelDisplay("products", false);
  }

  function handleCategoryFilter(event: ChangeEvent<HTMLSelectElement>): void {
    setSelectedCategory(event.target.value);
  }

  function handleBrandFilter(event: ChangeEvent<HTMLSelectElement>): void {
    setSelectedBrand(event.target.value);
  }

  function handleProductFilter(event: ChangeEvent<HTMLSelectElement>): void {
    setSelectedProductName(event.target.value);
  }

  function handleCalculateTotal(): void {
    const enteredSize = parseFloat(size);
    if (isNaN(enteredSize)) {
      setSize(null);
      setTotal(0);
      return;
    }
    const prod = findProductByName(selectedProductName);
    if (!prod) {
      setTotal(0);
      return;
    }
    const price = findPriceBySize(prod, enteredSize);
    setTotal(enteredSize * price);
  }

  function findProductByName(name: string) {
    const nameLower = toLower(name);
    const prods =
      data?.data?.filter((item: any) => toLower(item.name) === nameLower) || [];

    if (prods.length === 0) {
      return null;
    }

    const sizes = prods.map((item: any) => ({
      size: item.sq_ft_min ?? 0,
      price: item.price,
    }));

    const prod = prods[prods.length - 1];

    return {
      ...prod,
      name,
      sizes,
      unit: prod.unit ?? "sq ft",
    };
  }

  return (
    <div>
      <SlidingPane
        className="sliding-pane-class"
        overlayClassName="sliding-pane-overlay"
        isOpen={isPaneOpen}
        title="Products and Services"
        width="40%"
        onRequestClose={handleClosePane}
      >
        {isError && (
          <div className="alert alert-danger" role="alert">
            {error.message}
          </div>
        )}
        <div className="container-fluid">
          <div className="list-group">
            <div className="list-group-item">
              <select
                className="form-select my-3"
                onChange={handleCategoryFilter}
                value={selectedCategory}
              >
                <option value="">Choose Category</option>
                {categories.map((category: any) => (
                  <option key={category} value={category}>
                    {toTitleCase(category)}
                  </option>
                ))}
              </select>
              {!isEmpty(brands) && (
                <select
                  className="form-select my-3"
                  onChange={handleBrandFilter}
                  value={selectedBrand}
                >
                  <option value="">Brand</option>
                  {brands?.map((brand: any) => (
                    <option key={brand} value={brand}>
                      {toTitleCase(brand)}
                    </option>
                  ))}
                </select>
              )}
              {!isEmpty(products) && (
                <select
                  className="form-select my-3"
                  onChange={handleProductFilter}
                  value={selectedProductName}
                >
                  <option value="">Product</option>
                  {products?.map((prod: any) => (
                    <option key={prod} value={prod}>
                      {toTitleCase(prod)}
                    </option>
                  ))}
                </select>
              )}
              {!isEmpty(selectedProductName) && (
                <div className="form-group my-3">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                      placeholder="Size"
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={handleCalculateTotal}
                      >
                        Calculate
                      </button>
                    </div>
                  </div>

                  <div className="alert alert-secondary my-3" role="alert">
                    <div>Unit: {toUpper(selectedProduct?.unit)}</div>
                    {selectedProduct?.discount && (
                      <div>Discount: {selectedProduct.discount}</div>
                    )}
                    {selectedProduct?.notes && (
                      <div>Note: {selectedProduct.notes}</div>
                    )}
                  </div>
                </div>
              )}
              {total > 0 && (
                <div className="alert alert-info" role="alert">
                  Total: {currencyFormat(total, "$")}
                </div>
              )}
              <div className="d-flex justify-content-center my-3">
                <button
                  className="btn btn-primary"
                  onClick={handleAddLineClick}
                  disabled={!size}
                >
                  Add Lines
                </button>
              </div>
            </div>
          </div>
        </div>
      </SlidingPane>
    </div>
  );
};

export default ProposalProductsPane;
